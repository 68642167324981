import { RemixBrowser } from "@remix-run/react";
import { startTransition, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import { useLocation, useMatches } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { parseCookies } from "./utils/cookie";

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  dsn: "https://1c10b16d279012e3c0c83ed606326133@o112472.ingest.sentry.io/4505621465530368",
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    // Replay is only available in the client
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/flipsidecrypto\.xyz/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

if (window.rudderanalytics) {
  const cookies = parseCookies(document.cookie);
  if (cookies.visitor_id) {
    window.rudderanalytics.setAnonymousId(cookies.visitor_id);
  }
}

startTransition(() => {
  hydrateRoot(
    document,
    // <StrictMode>
    <RemixBrowser />,
    // </StrictMode>,
  );
});
