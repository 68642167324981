export function parseCookies(cookies: string) {
  const rawCookies = cookies.split("; ");
  const parsedCookies: Record<string, string> = {};

  for (let i = 0; i < rawCookies.length; i++) {
    const [cookieName, cookieValue] = rawCookies[i]!.split("=");
    if (!cookieName || !cookieValue) continue;
    parsedCookies[cookieName] = decodeURIComponent(cookieValue);
  }

  return parsedCookies;
}
